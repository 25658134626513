<template>
  <div>
    <dynamic-template :name="mainTemplate"></dynamic-template>
    <div class="top-sticky">
      <dynamic-template name="Logo"></dynamic-template>
      <dynamic-template name="Weather1"></dynamic-template>
      <dynamic-template name="Currency1"></dynamic-template>
    </div>
    <dynamic-template name="FooterBar"></dynamic-template>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout2',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    }
  }
};
</script>

<style lang="scss">
.top-sticky {
  position: fixed;
  top: 0;
  left: 0;
  background: url('/images/parts/top-sticky-bg.png') center top no-repeat;
  background-size: cover;
  width: 1432px;
  height: 202px;
  z-index: 5;
  & > * {
    display: inline-block;
  }
  .logo {
    margin: 12px 66px 0 40px;
    img {
      width: 230px;
      height: 170px;
      -o-object-fit: contain;
      object-fit: contain;
      object-position: center;
    }
  }
  .daily-info {
    .item {
      display: inline-block;
      padding: 22px 22px 18px;
      &.item-icon {
        padding: 42px 25px 0;
        img {
          width: auto;
          height: 50px;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .top-sticky {
    width: 960px;
    height: 150px;
    background-size: 100% auto;
    .logo {
      margin: 12px 36px 0 24px;
      img {
        width: 160px;
        height: 110px;
      }
    }
    .daily-info {
      .item {
        display: inline-block;
        padding: 19px 14px 15px;
        &.item-icon {
          padding: 28px 20px 0;
          img {
            width: auto;
            height: 36px;
          }
        }
      }
    }
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
}
@media (min-width: 1950px) and (max-width: 2048px) {
}
@media (min-width: 2049px) {
  .top-sticky {
    width: 2299px;
    height: 342px;
    background-size: 100% auto;
    .logo {
      margin: 20px 66px 0 44px;
      img {
        width: 376px;
        height: 270px;
      }
    }
    .daily-info {
      .item {
        display: inline-block;
        padding: 18px 28px 16px;
        &.item-icon {
          padding: 66px 36px 0;
          img {
            width: auto;
            height: 86px;
          }
        }
      }
    }
  }
}
</style>
